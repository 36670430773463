<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar></nav-bar>
        <main-block>
          <block-head title="Returned Leads" :description="'Total '+pagination.totalRecords+' leads.'"></block-head>
          <block-content>
            <card no-padding>
              <template v-slot:grouped>
                <div class="card-inner position-relative card-tools-toggle">
                  <card-header title="">
                    <card-tools>
                      <div class="form-inline flex-nowrap gx-3">
                        <div class="form-wrap w-150px">
                          <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                            <option value="">Bulk Action</option>
                            <option value="to_marketplace">To Marketplace</option>
                            <option value="delete">Delete</option>
                          </select>
                        </div>
                        <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">Apply</nk-button>
                          </span>
                          <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                        </div>
                      </div>
                    </card-tools>
                    <card-tools class="mr-n1">
                      <btn-toolbar>
                        <btn-toolbar-toggle-wrap id="advisors_toolbar">
                          <li>
                            <nk-button is-link type=""
                                       is-icon-only-button=""
                                       class="search-toggle"
                                       v-on:click="searchBarActive=true"
                                       :class="{active: searchBarActive}">
                              <nio-icon icon="ni-search"></nio-icon>
                            </nk-button>
                          </li>
                          <li class="btn-toolbar-sep"></li>
                          <li>
                            <drop-down
                                icon="ni-filter-alt"
                                size="lg"
                                direction="right"
                                :dot="( statusFilter !== 'all' || reasonFilter !== '' ? 'primary':'')"
                                title="Advisors Filter">
                              <template v-slot:body>
                                <row class="gx-6 gy-3">
                                  <column default="12" md="8">
                                    <label class="overline-title overline-title-alt" for="status_filter">Status</label>
                                    <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                      <option value="all">All</option>
                                      <option value="3">Pending Review</option>
                                      <option value="2">Return Rejected</option>
                                      <option value="1">Return Accepted</option>
                                    </select>
                                  </column>
                                  <column default="12" class="mt-2">
                                    <label class="overline-title overline-title-alt" for="reason_filter">Filter by reason</label>
                                    <select class="form-control form-control-sm" id="reason_filter" v-model="reasonFilter">
                                      <option value="">All</option>
                                      <option v-for="reason in leadReturnReasons" :key="'rr'+reason.id" :value="reason.id">{{ reason.reason }}</option>
                                    </select>
                                  </column>
                                </row>
                              </template>
                              <template v-slot:footer>
                                <nk-button type="dark" dim v-on:click="getLeads(true)">Filter</nk-button>
                                <a class="clickable" href="javascript:;" v-on:click="[statusFilter='all',reasonFilter='',getLeads(true)]">Reset Filter</a>
                              </template>
                            </drop-down>
                          </li><!-- li -->
                          <li>
                            <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                              <ul class="link-check">
                                <li><span>Order</span></li>
                                <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getLeads(true)]">ASC</a></li>
                                <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getLeads(true)]">DESC</a></li>
                              </ul>
                            </drop-down>
                          </li><!-- li -->
                        </btn-toolbar-toggle-wrap>
                      </btn-toolbar>
                    </card-tools>
                  </card-header>
                  <card-search
                      placeholder="Search advisor name, lead #, lead ID"
                      :is-active="searchBarActive"
                      @do-search="searchAdvisors"
                      :search-term="searchFilter"
                      @close-search="[searchBarActive=false, searchAdvisors('')]"></card-search>
                </div>
                <div class="d-flex py-5 justify-center"  v-if="loadingData">
                  <ion-spinner></ion-spinner>
                </div>
                <template v-else>
                  <div class="card-inner p-0">
                    <special-table>
                      <table-row is-heading>
                        <table-col has-check-box>
                          <b-check-box id="select_all" v-model="selectAll"></b-check-box>
                        </table-col>
                        <table-col>
                          <span class="sub-text">Lead#</span>
                        </table-col>
                        <table-col>
                          <span class="sub-text">Returned by</span>
                        </table-col>
                        <table-col break-point="mb">Return date</table-col>
                        <table-col break-point="md">Status</table-col>
                        <table-col break-point="lg">Reason</table-col>
                        <table-col class="nk-tb-col-tools"></table-col>
                      </table-row>

                      <table-row v-for="lead in leads" :key="'ld'+lead.id">
                        <table-col has-check-box>
                          <b-check-box :id="'lead'+lead.id" v-model="lead.selected" :key="'cb'+lead.id"></b-check-box>
                        </table-col>
                        <table-col>{{ lead.lead_number }}</table-col>
                        <table-col>
                          <user-small-card
                              :user-name="lead.first_name+' '+lead.last_name"
                              :user-email="lead.email" :image="lead.dp" :initials="lead.initials"></user-small-card>
                        </table-col>
                        <table-col break-point="mb">
                          <span class="tb-lead">{{ lead.return_date }}</span>
                          <span>{{ lead.return_time_passed }} ago</span>
                        </table-col>
                        <table-col break-point="md">
                          <span v-if="lead.return_status === 1" class="text-success">Returned</span>
                          <span v-else-if="lead.return_status === 2" class="text-danger">Rejected</span>
                          <span v-else-if="lead.return_status === 3" class="text-muted">Pending</span>
                          <span v-else class="text-warning">Unknown</span>
                        </table-col>
                        <table-col break-point="lg">{{ lead.reason }}</table-col>
                        <table-col class="nk-tb-col-tools">
                          <ul class="nk-tb-actions gx-1">
                            <li>
                              <drop-down-list>
                                <list-item icon="ni-eye" text="View Details" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                                <list-item icon="ni-reply-fill" text="Respond"
                                           v-if="lead.return_status === 3"
                                           v-on:click="prepareLeadForResponse(lead)"></list-item>
                              </drop-down-list>
                            </li>
                          </ul>
                        </table-col>
                      </table-row>

                    </special-table>

                  </div>
                  <div class="card-inner" v-if="pagination.totalRecords > 10">
                    <pagination
                        v-model="pagination.page"
                        :records="pagination.totalRecords"
                        :per-page="10"
                        @paginate="paginationCallback"
                        theme="bootstrap4"
                        :options="{chunk: 5, texts:{count: '||'}}"/>
                  </div>
                </template>
              </template>
            </card>
          </block-content>
        </main-block>
      </div>

      <ion-modal :is-open="returnResponse.isModalOpen" @didDismiss="setStatusModalOpenStatus(false)">
        <i-modal title="Respond to lead return" @modal-close="setStatusModalOpenStatus(false)">
          <p class="overline-title mb-1">{{ $t('Reason') }}</p>
          <p>{{ returnResponse.leadData.reason }}</p>

          <p class="overline-title mb-1">{{ $t('Explanation') }}</p>
          <p>{{ returnResponse.leadData.explanation }}</p>
          <hr class="border-top"/>
          <form-group>
            <label class="overline-title d-block">Change status</label>
            <b-radio name="new_return_status" id="nrs_1" label="Accept" value="1" v-model="returnResponse.new_status"/>
            <b-radio name="new_return_status" id="nr_2" label="Reject" value="2" v-model="returnResponse.new_status" class="ml-3"/>
          </form-group>
          <form-group>
            <label class="overline-title">Reason/comment</label>
            <textarea class="form-control" v-model="returnResponse.comment"></textarea>
          </form-group>
          <template v-slot:footer class="p-5">
            <nk-button type="primary" class="mr-2" v-on:click="submitReturnResponse">Submit</nk-button>
            <a href="javascript:;" class="link link-light" v-on:click="setStatusModalOpenStatus(false)">Cancel</a>
          </template>
        </i-modal>
      </ion-modal>

    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonPage, IonSpinner, alertController, loadingController, toastController} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import VerticalNavMenu from "@/@core/layouts/vertical-nav-menu/VerticalNavMenu";
import NavBar from "@core/layouts/app-nav-bar/NavBar";
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import DropDown from "@core/components/dropdown/DropDown";

import { Column, Row, FormGroup } from "@core/layouts"
import {BRadio} from "@core/components/bp-form";

import Pagination from "v-pagination-3"
import BlockHead from "@core/layouts/main-block/components/BlockHead";
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import DropDownList from "@core/components/dropdown-list/DropDownList";
import ListItem from "@core/components/dropdown-list/ListItem";
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import {IonModal} from "@ionic/vue";
import IModal from "@core/components/ion-modal/IModal";


export default defineComponent({
  components: {
    ListItem,
    DropDownList,
    UserSmallCard,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    FormGroup,
    BRadio,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    NkButton,
    NioIcon,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    NavBar,
    VerticalNavMenu,
    IonContent,
    IonPage,
    IonSpinner,
    IonModal,
    IModal,
    Pagination,
  },
  setup() {

    const router  = useRouter()
    const store   = useStore()
    const leadStatuses = store.state.general_data.leads_statuses

    let searchFilter  = ref('');
    let statusFilter  = ref('3')
    let reasonFilter  = ref('')
    let order         = ref('asc')

    let loadingData = ref(false)

    let selectAll    = ref(false)

    let leads     = ref([])

    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      await getLeads(true)
    }

    let cancelToken = false
    const getLeads = async (reset) => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/admin/leads/returned', {
            page: pagination.page,
            status: statusFilter.value,
            search: searchFilter.value,
            reason: reasonFilter.value,
            order: order.value
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(reset){
              leads.value = []
            }
            for(let x in resp.data.data){
              resp.data.data[x].selected = false;
              leads.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }

    watch(searchFilter, () => getLeads(true))

    const searchAdvisors = (search) => {
      searchFilter.value = search
    }

    onMounted(()=> getLeads())

    watch(selectAll, (n) => {
      for(let x in leads.value){
        leads.value[x].selected = n
      }
    })

    const deleteRecords = async ( _records, record_type ) => {

      const loading = await loadingController.create({message: 'Processing...'})
      const toast   = await toastController.create({position: "top", duration: 3000})

      let ids = []
      for(let x in _records){
        ids.push( _records[x].id)
      }

      let iAlert = await alertController.create({
        message: `Are you sure to delete the ${_records.length} selected ${record_type+(_records.length > 1 ? 's' : '')}?`,
        buttons: [
          'Cancel',
          {
            text: 'Confirm', handler: () => {
              loading.present()
              axios.post('/api/admin/leads/delete', {ids: JSON.stringify(ids)})
                  .then( resp => {
                    toast.message = resp.data.success ? 'Thank you! action completed' : resp.data.message
                    toast.color   = resp.data.success ? "primary" : "danger"
                    if(resp.data.success){
                      // Remove the deleted entries from the advisors array
                      for(let x in ids){
                        for(let y in leads.value){
                          if(leads.value[y].id === ids[x]){
                            leads.value.splice(y,1)
                            break;
                          }
                        }
                      }
                      // All deleted, no entry left in the advisor array? and previous pages exists
                      if( !leads.value.length && pagination.page > 1 ){
                        // Deduct from total records value
                        pagination.totalRecords -= ids.length
                        // Goto previous page
                        pagination.page--
                        getLeads(true)
                      }
                    }
                    toast.present()
                  })
                  .catch(error => {
                    toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                    toast.color = "danger"
                    toast.present()
                  })
                  .then( () => loading.dismiss())
            }
          }
        ]
      })
      await iAlert.present()
    }

    const addToMarketplace = async ( _leads ) => {

      const loading = await loadingController.create({message: 'Processing...'})
      const toast   = await toastController.create({position: "top", duration: 3000})

      let ids = []
      for(let x in _leads){
        ids.push( _leads[x].id)
      }

      let iAlert = await alertController.create({
        cssClass: 'text-lg-left',
        header: `Add lead${ids.length > 1 ? 's' : ''} to marketplace`,
        message: 'Please provide price &euro;',
        inputs: [
          {
            name: 'price',
            type: 'text',
            cssClass: 'mt-0',
          }
        ],
        buttons: [
          'Cancel',
          {
            text: 'Confirm', handler: (d) => {

              let price = parseFloat(d.price.trim());
              if(!price || price < 1){
                toastController.create({position: "top", duration: 3000, message: 'Please provide a valid price.', color: "danger"}).then((t) => t.present())
                return false
              }

              loading.present()
              axios.post('/api/admin/marketplace/add', {ids: JSON.stringify(ids), price: price})
                  .then( resp => {
                    toast.message = resp.data.success ? 'Thank you! leads added to marketplace, you can view it under the marketplace.' : resp.data.message
                    toast.color   = resp.data.success ? "primary" : "danger"
                    if(resp.data.success){
                      // Remove the selected leads from the leads array
                      for(let x in ids){
                        for(let y in leads.value){
                          if(leads.value[y].id === ids[x]){
                            leads.value.splice(y,1)
                            break;
                          }
                        }
                      }
                      // All deleted, no entry left in the advisor array? and previous pages exists
                      if( !leads.value.length && pagination.page > 1 ){
                        // Deduct from total records value
                        pagination.totalRecords -= ids.length
                        // Goto previous page
                        pagination.page--
                        getLeads(true)
                      }
                    }
                    toast.present()
                  })
                  .catch(error => {
                    toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                    toast.color = "danger"
                    toast.present()
                  })
                  .then( () => loading.dismiss())
            }
          }
        ]
      })
      await iAlert.present()
    }

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selectedRecords = []
      for(let x in leads.value){
        if( leads.value[x].selected){
          selectedRecords.push( leads.value[x])
        }
      }
      if( selectedRecords.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'delete' ){
        await deleteRecords(selectedRecords, 'lead')
      }
      else if( bulkSelectedAction.value === 'to_marketplace'){
        await addToMarketplace( selectedRecords )
      }
    }

    const returnResponse = reactive({
      isModalOpen: false,
      leadData: {},
      new_status: '',
      comment: '',
    })

    const setStatusModalOpenStatus = (st) => returnResponse.isModalOpen = st
    const prepareLeadForResponse = (lead) => {
      returnResponse.leadData = lead
      returnResponse.comment = lead.admin_comment
      setStatusModalOpenStatus(true)
    }

    const submitReturnResponse = async () => {

      const loader = await loadingController.create({message: 'Please wait...'})
      await loader.present()

      const toast  = await toastController.create({duration: 4000})

      axios.post('/api/admin/leads/returned/respond', {return_id: returnResponse.leadData.return_id, status: returnResponse.new_status, comment: returnResponse.comment})
      .then( resp => {
        if(resp.data.success){
          setStatusModalOpenStatus(false)
          returnResponse.leadData.return_status = parseInt(returnResponse.new_status)
          returnResponse.leadData.admin_comment = returnResponse.comment
          toastController.create({duration:3000, message: 'Thank you! operation was successful', color: "primary"}).then((t)=> t.present())
        }
        else{
          toastController.create({duration:3000, message: resp.data.message, color: "danger"}).then((t)=> t.present())
        }
      })
      .catch( error => {
        toast.message = 'Error: '+error.response.status+' '+error.response.statusText
        toast.color = 'danger'
        toast.present()
      })
      .then( () => loader.dismiss())
    }

    const leadReturnReasons = store.state.general_data.lead_return_reasons

    return {

      leadStatuses,
      leads,
      getLeads,
      loadingData,
      router,
      returnResponse,
      setStatusModalOpenStatus,
      prepareLeadForResponse,
      submitReturnResponse,
      leadReturnReasons,

      searchAdvisors,

      reasonFilter,
      searchFilter,
      statusFilter,
      order,
      pagination,
      paginationCallback,

      searchBarActive,
      selectAll,

      bulkSelectedAction,
      bulkAction,
      deleteRecords,
      addToMarketplace,
    }
  }
});

</script>
